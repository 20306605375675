import { useEffect, useState } from "react";
import { WorkHistory } from "../../API";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";
/* istanbul ignore next */
export function useWorkHistory(nodeIds: string[]): Result<WorkHistory[]> {
    const workHistoryTable = useDatabase().workHistory;
    const workHistoryRevision = useUpdateRevision(workHistoryTable);
    const [result, setResult] = useState<Result<WorkHistory[]>>({});

    useEffect(() => {
        const ac = new AbortController();
        const getWorkHistory = async (
            nodeId: string,
            signal: AbortSignal,
        ): Promise<readonly WorkHistory[]> => {
            const result = await workHistoryTable.findGreedily(
                {
                    index: "listWorkHistoryByNodeId",
                    nodeId,
                },
                { signal },
            );
            return result;
        };
        Promise.all(
            nodeIds.map((nodeId) => getWorkHistory(nodeId, ac.signal)),
        ).then(
            (result) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult({ data: sortWorkHistory(result.flat()) });
            },
            (error) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult({ error });
            },
        );
    }, [nodeIds, workHistoryTable, workHistoryRevision]);

    return result;
}

/**
 * メモをtimeに従ってソートする処理
 * @param workHistory
 * @returns ソートされたメモ情報配列
 */
function sortWorkHistory(workHistory: readonly WorkHistory[]): WorkHistory[] {
    const array = [...workHistory];
    return array.sort((a: WorkHistory, b: WorkHistory) => {
        if (a.time == null) {
            return 1;
        }
        if (b.time == null) {
            return -1;
        }
        return Date.parse(a.time) - Date.parse(b.time);
    });
}
