import { schemas as s, validate } from "validia";

export namespace Incident {
    /**
     * {@link Incident} クラスの初期化パラメーター
     */
    export interface ConstructionParameters {
        /**
         * 登録日時
         *
         * @default new Date()
         */
        readonly createdAt?: Date | number | string | undefined;
        /**
         * 登録者ID (E-mail)
         *
         * @default "unknown@example.com"
         */
        readonly createdUserId?: string | undefined;
        /**
         * 登録者名
         *
         * @default "unknown"
         */
        readonly createdUsername?: string | undefined;
        /**
         * 受付番号
         *
         * @default a random UUID
         */
        readonly id?: string | undefined;
        /**
         * 障害発生日時
         *
         * @default new Date()
         */
        readonly occurredAt?: Date | number | string | undefined;
        /**
         * 緊急度
         *
         * @default "D"
         */
        readonly priority?: Incident.Priority | undefined;
        /**
         * 対応状況
         *
         * @default "triage"
         */
        readonly state?: Incident.State | undefined;
        /**
         * 件名
         *
         * @default "untitle"
         */
        readonly subject?: string | undefined;
        /**
         * テナントID
         *
         * @default "unknown"
         */
        readonly tenantId?: string | undefined;
    }

    /**
     * 報告の優先度
     */
    export type Priority = "A" | "B" | "C" | "D";

    /**
     * 報告の処理状態
     *
     * - `triage` .......... 受付
     * - `investigating` ... 調査中
     * - `doing` ........... 対応中
     * - `closed` .......... 完了
     */
    export type State = "triage" | "investigating" | "doing" | "closed";
}

/**
 * ユーザーからの報告を表すモデル
 */
export class Incident {
    /** 登録日時 */
    readonly createdAt: Date;
    /** 登録者ID （E-mail） */
    readonly createdUserId: string;
    /** 登録者名 */
    readonly createdUsername: string;
    /** 受付番号 */
    readonly id: string;
    /** 発生日時 */
    readonly occurredAt: Date;
    /** 緊急度 */
    readonly priority: Incident.Priority;
    /** 対応状況 */
    readonly state: Incident.State;
    /** 件名 */
    readonly subject: string;
    /** テナントID */
    readonly tenantId: string;

    /**
     * 新しい {@link Incident} インスタンスを初期化します。
     *
     * @param parameters 初期化パラメーター
     */
    constructor(parameters: Incident.ConstructionParameters = {}) {
        const {
            createdAt = new Date(),
            createdUserId = "unknown@example.com",
            createdUsername = "unknown",
            id = crypto.randomUUID(),
            occurredAt = new Date(),
            priority = "D",
            state = "triage",
            subject = "untitled",
            tenantId = "unknown",
        } = parameters;

        this.createdAt = new Date(createdAt);
        this.createdUserId = createdUserId;
        this.createdUsername = createdUsername;
        this.id = id;
        this.occurredAt = new Date(occurredAt);
        this.priority = priority;
        this.state = state;
        this.subject = subject;
        this.tenantId = tenantId;
    }

    /**
     * JSON オブジェクトを解析して {@link Incident} インスタンスを作
     * 成します。
     *
     * @param data 解析する値
     * @returns 解析結果
     */
    static parse(this: void, data: unknown): Incident {
        validate(IncidentSchema, data, { name: "data" });

        return new Incident(data);
    }

    equals(that: Incident): boolean {
        return (
            this.createdAt === that.createdAt &&
            this.createdUserId === that.createdUserId &&
            this.createdUsername === that.createdUsername &&
            this.id === that.id &&
            this.occurredAt === that.occurredAt &&
            this.priority === that.priority &&
            this.state === that.state &&
            this.subject === that.subject &&
            this.tenantId === that.tenantId
        );
    }

    with(parameters: Incident.ConstructionParameters): Incident {
        const {
            createdAt,
            createdUserId,
            createdUsername,
            id,
            occurredAt,
            priority,
            state,
            subject,
            tenantId,
        } = parameters;
        const next = new Incident({
            createdAt: createdAt ?? this.createdAt,
            createdUserId: createdUserId ?? this.createdUserId,
            createdUsername: createdUsername ?? this.createdUsername,
            occurredAt: occurredAt ?? this.occurredAt,
            id: id ?? this.id,
            priority: priority ?? this.priority,
            state: state ?? this.state,
            subject: subject ?? this.subject,
            tenantId: tenantId ?? this.tenantId,
        });

        return this.equals(next) ? this : next;
    }
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const IncidentSchema = s.object({
    createdAt: s.string(),
    createdUserId: s.string(),
    createdUsername: s.string(),
    id: s.string(),
    occurredAt: s.string(),

    priority: s.enum("A" as const, "B" as const, "C" as const, "D" as const),
    state: s.enum(
        "triage" as const,
        "investigating" as const,
        "doing" as const,
        "closed" as const,
    ),
    subject: s.string(),
    tenantId: s.string(),
});
