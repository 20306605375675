import React, { useCallback, useMemo } from "react";
import { useStorageValue } from "../../../hooks/use-storage-value";
import { Incident } from "./incident";

export namespace useIncidentList {
    export interface Result {
        incidents: readonly Incident[];
        createIncident: (incident: Incident) => Promise<void>;
        deleteIncident: (incident: Incident) => Promise<void>;
        updateIncident: (incident: Incident) => Promise<void>;
    }
}

export function useIncidentList(): useIncidentList.Result {
    const [jsonText, setJsonText] = useStorageValue(
        localStorage,
        "1576994d-44dd-4de9-a1a3-97fc745f80aa",
    );
    const incidents = useMemo(() => parse(jsonText), [jsonText]);

    const createIncident = useCallback(
        (incident: Incident) => create(incident, setJsonText),
        [setJsonText],
    );

    const deleteIncident = useCallback(
        (incident: Incident) => delete_(incident, setJsonText),
        [setJsonText],
    );

    const updateIncident = useCallback(
        (incident: Incident) => update(incident, setJsonText),
        [setJsonText],
    );

    return {
        createIncident,
        deleteIncident,
        updateIncident,
        incidents,
    };
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

function parse(jsonText: string): readonly Incident[] {
    try {
        const data = JSON.parse(jsonText || "[]");
        if (Array.isArray(data)) {
            return data.map(Incident.parse);
        }
    } catch (error: unknown) {
        console.error("ユーザー報告のデータが不正でした。", error, jsonText);
    }

    return [];
}

function create(
    incident: Incident,
    setJsonText: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setJsonText((prev) => {
            try {
                const incidents = parse(prev);
                if (incidents.some((u) => u.id === incident.id)) {
                    throw new Error(`'${incident.id}' exists already`);
                }
                return JSON.stringify([...incidents, incident]);
            } catch (error: unknown) {
                reject(error);
                return prev;
            } finally {
                resolve();
            }
        });
    });
}

function delete_(
    incident: Incident,
    setJsonText: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setJsonText((prev) => {
            try {
                const incidents = parse(prev);
                const index = incidents.findIndex((u) => u.id === incident.id);
                return index === -1
                    ? prev
                    : JSON.stringify([...incidents].splice(index, 1));
            } catch (error: unknown) {
                reject(error);
                return prev;
            } finally {
                resolve();
            }
        });
    });
}

function update(
    incident: Incident,
    setJsonText: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        setJsonText((prev) => {
            try {
                const incidents = parse(prev);
                const index = incidents.findIndex((u) => u.id === incident.id);
                if (index === -1) {
                    throw new Error(`'${incident.id}' was not found`);
                }
                return JSON.stringify(
                    [...incidents].splice(index, 1, incident),
                );
            } catch (error: unknown) {
                reject(error);
                return prev;
            } finally {
                resolve();
            }
        });
    });
}
